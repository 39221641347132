@keyframes fadein {
    to {
        opacity: 1;
    }
}

#checkout {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 135px;
    width: 100%;

    button {
        opacity: 0;
        animation: fadein 1s linear 0.7s forwards;
        position: absolute;
        bottom: -65%;
    }
}
