@import "../../variables.scss";

#header {
    position: relative;
    width: 100%;
    height: $headerHeight;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 40px 0;
    box-sizing: content-box;

    img.logo {
        height: 100%;
    }
    .profileContainer {
        position: absolute;
        height: 40px;
        width: 40px;
        top: 40px;
        right: 20px;
        border-radius: 50%;
        cursor: pointer;
        background-size: 100% 130%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .profile {
            height: 90%;
            border-radius: 50%;
        }
    }
}

@media (min-width: $tablet-width) {
    #header {
        .profileContainer {
            height: 60px;
            width: 60px;
            top: unset;
            right: 40px;
        }
    }
}

@media (min-width: $desktop-width) {
    #header {
        .profileContainer {
            height: 80px;
            width: 80px;
            right: 60px;
        }
    }
}
