@import "../../variables.scss";

@keyframes shopFadeIn {
    to {
        opacity: 1;
    }
}

@keyframes bundlesEnter {
    to {
        transform: translateY(0px);
    }
}

#shop {
    width: 100%;
    animation: shopFadeIn 0.5s linear forwards;
    opacity: 0;
    height: 100%;
    position: relative;
    z-index: 2;

    .container {
        min-height: calc(100vh - $footerHeight - $headerHeight);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bundlesContainer {
        width: 100%;
        max-width: 1400px;
        margin: 0 auto 40px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 8px;
        gap: 2em;
    }
}
