@import "../../variables.scss";

#loginPage {
    padding-top: 60px;
    height: calc(100vh - 200px);
    min-height: 550px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    a {
        color: white;
    }

    span {
        max-width: 400px;
        width: 80%;
    }

    h2 {
        max-width: 90%;
        margin: 0;
    }

    .images {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;

        .logo {
            max-width: 90%;
            width: 500px;
        }
        .flippedCoin {
            height: 15%;
            position: absolute;
    
            &:nth-child(1) {
                left: 10%;
                top: 0%;
            }
            &:nth-child(2) {
                left: 30%;
                bottom: -10%;
            }
            &:nth-child(3) {
                right: 0%;
                top: 30%;
            }
        }
        .pile {
            height: 30%;
            position: fixed;
            bottom: 0;
            transform: translate(-27%, 39%);
            left: 0;
        }
    }

    .loginContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 500px;
        max-width: 100%;

        img.loginButton {
            max-width: 90%;
            margin-bottom: 12px;
            cursor: pointer;
            &:active {
                transform: scale(95%);
            }
        }
        p {
            text-align: center;
            max-width: 80%;
        }
    }
}

@media (min-width: $desktop-width) {
    #loginPage {
        .images {
            .flippedCoin {
                height: 25%;
            }
        }
    }
}
