@keyframes fadein {
    to {
        opacity: 1;
    }
}

#orderSet {
    opacity: 0;
    height: 100%;
    width: 100%;
    max-width: 500px;
    animation: fadein 1s linear 0.7s forwards;

    .products {
        height: 135px;
        width: 100%;
        max-width: 600px;
        border-radius: 20px;
        position: relative;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 20px;
        z-index: 5;
        background-color: rgba($color: #000000, $alpha: 0.2);
    
        .product {
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                font-size: 16px;
            }
    
            img {
                height: 55px;
                margin-bottom: 16px;
            }
        }
    }
}
