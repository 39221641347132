@import "../../variables.scss";

#footer {
    width: 100%;
    position: relative;
    height: $footerHeight;
    background-color: transparent;
    font-family: sans-serif;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;

    .footerContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 100px;
        position: relative;
        top: -100px;

        div {
            margin: 0 8px;    
    
            a {
                color: inherit;
            }
        }
    }

    img.cloud {
        position: absolute;
        height: 120px;
        max-width: 100%;

        &.left{
            left: 0;
        }
        &.right{
            right: 0;
        }
    }

    .pile {
        height: 100%;
        position: absolute;
        bottom: 0;
        transform: translate(-27%, 39%);
        left: 0;
        z-index: 10;
    }

    h6 {
        cursor: pointer;
    }
}

@media (min-width: $tablet-width) {
    #footer {
        height: $footerHeightTablet;
        .footerContainer {
            padding-bottom: 60px;
        }
        img.cloud {
            height: 180px;
        }
    }
}

@media (min-width: $desktop-width) {
    #footer {
        height: $footerHeightDesktop;
        img.cloud {
            height: 300px;
        }
        .pile {
            height: 120%;
        }
    }
}
