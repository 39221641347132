@import "../../variables.scss";

#cookieConesnt {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
    font-size: 12px;
    z-index: 5;

    a {
        color: white;
    }

    button{
        color: white;
        padding: 8px 16px;
        cursor: pointer;
        border: 0;
        outline: 0;
    }

    .banner {
        position: fixed;
        bottom: 0;
        min-height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 16px;
        box-sizing: border-box;
        background-color: black;
        color: white;

        p {
            max-width: 1000px;
            margin: 0 0 12px 0;
        }

        button.adjust {
            background-color: transparent;
            margin-right: 16px;
            text-decoration: underline;
        }
        button.accept {
            background-color: #77BA3F;
        }
    }

    .dialog {
        position: fixed;
        top: 40%;
        left: 50%;
        max-width: 500px;
        width: 90%;
        padding: 20px;
        transform: translate(-50%, -50%);
        background-color: white;

        h3 {
            margin: 0 0 40px 0;
        }

        p { 
            margin: 16px 0 32px 0;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        button {
            width: 100%;
            text-align: center;
            background-color: #77BA3F;
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 30px;

        &.disabled {
            opacity: 0.4;
        }

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        span {
            width: 100%;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: .4s;
            border-radius: 15px;

            &:before {
                position: absolute;
                content: "";
                height: 28px;
                width: 28px;
                left: 1px;
                bottom: 1px;
                background-color: white;
                transition: .4s;
                border-radius: 50%;
            }
        }

        input:checked + .slider {
            background-color: #77BA3F;
        }

        input:checked + .slider:before {
            transform: translateX(20px);
        }
    }
}

@media (min-width: $tablet-width) {
    #cookieConesnt .dialog{
        top: 50%;
    }
}
