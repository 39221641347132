@import "../../variables.scss";

.transparentOverlay{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.infoModal {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba($color: #000000, $alpha: 0.6);
    color: white;
    padding: 24px;
    padding-top: 80px;
    overflow-y: scroll;

    span {
        position: absolute;
        right: 20px;
        top: 40px;
        cursor: pointer;
    }
    h3 {
        margin-bottom: 24px;
    }
}

@media (min-width: $tablet-width) {
    .infoModal {
        width: 80%;
        height: 80%;
        max-width: 1000px;
        max-height: 800px;
    }
}
