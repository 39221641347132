@keyframes showLayout {
    0% {background-color: rgba($color: #000000, $alpha: 0);}
    100% {background-color: rgba($color: #000000, $alpha: 0.4);}
}

@keyframes hideLayout {
    0% {background-color: rgba($color: #000000, $alpha: 0.4);}
    100% {background-color: rgba($color: #000000, $alpha: 0);}
}

.overlay {
    animation: showLayout 1s forwards;
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &.fadeout {
        animation: hideLayout 0.3s forwards;
    }
}

@keyframes showModal {
    0% {opacity: 0;}
    40% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes hideModal {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

#Modal {
    animation: showModal 0.6s forwards;
    opacity: 0;
    max-width: 400px;
    width: 90%;
    padding: 20px;
    padding-top: 60px;
    position: relative;
    border-radius: 30px;
    border: 2px solid #693510;
    background-color: #F3D6A6;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.fadeout {
        animation: hideModal 0.3s forwards;
    }

    .closeButton {
        position: absolute;
        cursor: pointer;
        top: 20px;
        right: 20px;
        height: 32px;
    }

    h2 {
        text-shadow: 1px 1px 0 #693510, -1px -1px 0 #693510, 1px -1px 0px #693510, -1px 1px 0 #693510;
        margin: 0;
        margin-bottom: 16px;
    }

    h5 {
        margin-top: 0;
        margin-bottom: 20px;
        text-align: center;
    }

    img.button, button {
        height: 60px;
        width: 100%;
        cursor: pointer;
    }
}
