@import "../../variables.scss";

.greenButton {
    outline: 0;
    border: 0;
    width: 335px;
    height: 60px;
    border-radius: 7px;
    background: linear-gradient(180deg, #67D22F 0%, #409D16 100%);
    cursor: pointer;
    span {
        text-transform: initial;
    }
}
